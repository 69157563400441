<template>
  <div class="sub-page">
    <h2>My Profile</h2>
    <hr class="mt-2" />
    <div class="m-flex m-flex__center z-22 u-relative mt-5">
      <div class="thumbnail-wrapper">
        <div
          class="w-12 h-12 mb-3 cursor-pointer d-block"
          :style="{
            overflow: formData.profilePhoto ? 'hidden' : 'unset',
            'border-radius': formData.profilePhoto ? '50%' : '0%'
          }"
        >
          <img
            class="avatar-icon"
            :src="userIcon"
            alt="avatar-icon"
            style="width: 100%"
            @click="toggleUserOptions"
          />

          <img
            src="../../assets/icons/edit-icon1.png"
            alt=""
            class="edit-icon"
            @click="toggleUserOptions"
          />

          <div
            class="user__options"
            tabindex="-1"
            ref="userOptions"
            @blur="showDropdown = false"
            :class="{ active: showDropdown }"
          >
            <p
              @click="$refs['user-img'].click()"
              class="d-block p-1 text__grey bb-1 cursor-pointer user__options-list"
            >
              Change photo
            </p>
            <!-- <p
              class="p-1 text-red cursor-pointer user__options-list"
              @click="removePhoto"
            >
              Remove photo
            </p> -->
          </div>

          <input
            type="file"
            ref="user-img"
            hidden
            @change="uploadImg"
            accept="image/*"
          />
        </div>
      </div>
    </div>

    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="updateProfile">
        <div class="grid grid__layout gap-2 pb-8 bb-1 mb-3 grid__mobile">
          <Input
            class="col-6 m-col-12"
            type="text"
            placeholder="Full Name"
            label="Full Name"
            v-model="formData.fullName"
            name="Name"
            rules="required|fullName"
          />
          <Input
            class="col-6 m-col-12"
            type="text"
            placeholder="Email"
            label="Email"
            v-model="formData.email"
            :disabled="true"
            name="Email Address"
            rules="required|email"
          />

          <div class="col-6 m-col-12" style="z-index: 99 !important">
            <label class="fs-14 fw-600 text__dark-grey mb-1x"> Gender </label>
            <Dropdown
              :options="genders"
              @selected="setGender"
              :placeholder="genderPlaceholder"
              :border="true"
            />
          </div>

          <div class="col-6 m-col-12" style="z-index: 98 !important">
            <label class="fs-14 fw-600 text__dark-grey mb-1x">
              Date of Birth
            </label>
            <DatePicker
              v-model="formData.dateOfBirth"
              name="Date of Birth"
              placeholder="Date of Birth"
              :disabledDates="{
                from: new Date(2006, 0, 2)
              }"
            />
          </div>

          <div class="col-12">
            <label class="fs-14 fw-600 text__dark-grey mb-1x"> Address </label>
            <textarea
              class="h-16 p-2"
              placeholder="Address"
              v-model="formData.address"
            />
          </div>
          <div class="col-4 m-col-12 u-above z-24">
            <Input
              type="text"
              placeholder="Position"
              label="Position"
              v-model="formData.position"
              name="Position"
            />
          </div>
          <div
            class="col-4 m-col-12 u-above z-23"
            style="z-index: 97 !important"
          >
            <label class="fs-14 fw-600 text__dark-grey mb-1x">
              Experience
            </label>
            <Dropdown
              :options="experience"
              @selected="updateSelected($event, 'yearsOfExperience')"
              :placeholder="experienceLabel"
              :border="true"
            />
          </div>

          <div
            class="col-4 m-col-12 u-above z-23"
            style="z-index: 97 !important"
          >
            <label class="fs-14 fw-600 text__dark-grey mb-1x"> Industry </label>
            <Dropdown
              :options="industryList"
              @selected="updateSelected($event, 'industry')"
              :placeholder="formData.industry || 'Industry'"
              :border="true"
            />
          </div>
          <div
            class="col-6 m-col-12 u-above z-22"
            style="z-index: 96 !important"
          >
            <label class="fs-14 fw-600 text__dark-grey mb-1x"> Country </label>
            <Dropdown
              :options="countries"
              @selected="updateSelected($event, 'countryId')"
              :placeholder="formData.countryName"
              :border="true"
            />
          </div>
          <div
            class="col-6 m-col-12 u-above z-21"
            style="z-index: 95 !important"
          >
            <label class="fs-14 fw-600 text__dark-grey mb-1x"> State </label>
            <Dropdown
              :options="cities"
              @selected="updateSelected($event, 'stateId')"
              :placeholder="formData.stateName"
              :border="true"
              :disabled="!isNumber(formData.countryId)"
            />
          </div>
          <div class="col-6 m-col-12" style="z-index: 94 !important">
            <label class="fs-14 fw-600 text__dark-grey mb-1x">
              Languages Spoken
            </label>

            <selection-box
              @selected="setLanguages"
              label="Languages Spoken"
              :options="languages"
              :defaultValue="formData.currentLanguages"
            />
          </div>

          <div class="col-6 m-col-12" style="z-index: 93 !important">
            <div class="fs-14 fw-500 text__dark-grey mb-1">
              National Youth Service Corps (NYSC) status
            </div>

            <Dropdown
              :options="nyscStatuses"
              @selected="setNyscStatus"
              :placeholder="nyscLabel"
              :border="true"
            />
          </div>

          <div class="col-12">
            <label class="fs-14 fw-600 text__dark-grey mb-1x">
              Profile Summary
            </label>
            <textarea
              placeholder="Add a description"
              v-model="formData.profileSummary"
              class="h-16 p-2"
              maxlength="1000"
            />
          </div>
        </div>

        <div class="flex flex-end">
          <Button
            :loading="updatingProfile"
            :disabled="invalid || updatingProfile"
            class="w-20"
            theme="blue"
          >
            Save Changes
          </Button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import DatePicker from 'vuejs-datepicker';
import 'vue-tel-input/dist/vue-tel-input.css';
import { mapActions, mapMutations } from 'vuex';
import PlaceholderImg from '../../assets/icons/avatar2-icon.svg';
import API from '@/const';
import jobseekerservice from '../../services/api/jobseekerservice';
import { languages } from '../../__mocks__/languages';
import SelectionBox from '../../components/SelectionBox';
import seekerService from '../../services/api/seekerService';
import publicService from '../../services/api/publicService';
import authService from '../../services/api/authService';

export default {
  name: 'PersonalInformation',
  props: {
    profileData: {
      type: Object
    }
  },
  components: {
    VueTelInput,
    DatePicker,
    SelectionBox
  },
  data() {
    return {
      industryList: [],
      countries: [],
      formData: {},
      profilePhoto: '',
      cities: [],
      positions: [
        { name: 'On Site', value: 'on_site' },
        { name: 'Hybrid', value: 'hybrid' },
        { name: 'Remote', value: 'remote' }
      ],
      experience: [
        { name: '1 Year', value: '1 Year' },
        { name: '2 Years', value: '2 Years' },
        { name: '3 Years', value: '3 Years' },
        { name: '4 Years', value: '4 Years' },
        { name: '5 Years', value: '5 Years' },
        { name: '6 Years', value: '6 Years' },
        { name: '7 Years', value: '7 Years' },
        { name: '8 Years', value: '8 Years' },
        { name: '9 Years', value: '9 Years' },
        { name: '10 Years', value: '10 Years' },
        { name: '10+ Years', value: '10+ Years' }
      ],
      profile: {},
      experienceLabel: 'Experience',
      positionLabel: 'Position',
      cityLabel: 'State',
      updatingProfile: false,
      showDropdown: false,
      genders: [
        { name: 'Male', value: 'Male' },
        { name: 'Female', value: 'Female' },
        { name: 'Other', value: 'Other' }
      ],
      languages,
      nyscStatuses: [
        { name: 'Completed', value: 'Completed' },
        { name: 'Exempted', value: 'Exempted' },
        { name: 'In progress', value: 'In progress' },
        { name: 'Not applicable', value: 'Not applicable' }
      ]
    };
  },
  created() {
    this.getIndustryList();
    this.getAllCountries();
    const profile = this.profileData.profile;

    this.formData = {
      fullName: profile.fullName,
      email: profile.email,
      phone: profile.phone,
      socialNetworks: profile.socialNetworks ?? [],
      profileSummary: profile.profileSummary,
      profilePhoto: profile.profileImage ?? '',
      yearsOfExperience: profile.yearsOfExperience ?? '',
      address: profile.address ?? '',
      dateOfBirth: profile.dateOfBirth ?? '',
      stateId: profile.state.id,
      stateName: profile.state.name,
      countryId: profile.country.id,
      countryName: profile.country.name,
      position: profile.position ?? '',
      languages: profile.languages,
      gender: profile.gender,
      nyscStatus: profile.nyscStatus,
      industry: profile.industry
    };

    this.formData.currentLanguages = profile.languages.map((l) => {
      return { name: l, value: l };
    });

    this.getCities(this.formData.countryId);

    // this.countries = countries;

    // const cities = countriesAndStates.find(
    //   (_, index) => profile.countryId === index
    // ).states;
    // this.cities = cities.map((city, val) => ({
    //   name: city.name,
    //   value: city.name,
    //   id: val
    // }));

    this.experienceLabel = profile.yearsOfExperience;
  },
  computed: {
    countryLabel() {
      const profile = this.profileData.profile;
      const country = this.countries.find(
        (country) => country.id === profile.countryId
      );

      return country ? country.name : 'Country';
    },
    genderPlaceholder() {
      const profile = this.profileData.profile;
      return profile.gender || 'Gender';
    },
    nyscLabel() {
      const profile = this.profileData.profile;
      return (
        profile.nyscStatus ||
        'Select National Youth Service Corps (NYSC) status'
      );
    },

    // countries() {
    //   return this.$getCountries.map((country) => ({
    //     name: country.name,
    //     value: country.sortname,
    //     id: country.id
    //   }));
    // },
    userIcon() {
      return this.formData.profilePhoto || PlaceholderImg;
    }
  },
  methods: {
    ...mapActions({
      $updateProfile: 'apimodel/makeRequest'
    }),
    ...mapMutations({
      updateLoggedUser: 'auth/updateLoggedUser',
      setUserData: 'authnew/setUserData',
      setProfile: 'authnew/setProfile'
    }),
    getIndustryList() {
      publicService
        .getIndustyList()
        .then((res) => {
          // TODO: set real industry from api
          this.industryList = res.data.industries.map((industry) => ({
            name: industry.title,
            label: industry.title
          }));
        })
        .catch()
        .finally();
    },
    updateSelected({ value, id = '', name }, key) {
      if (key === 'countryId') {
        this.formData.stateId = null;
        this.formData[key] = id;
        // this.cityLabel = "";
        this.getCities(id);
        return;
      }

      //   this.cityLabel = this.cities.find(
      //     (_, index) => profile.stateId === index
      //   ).name;

      //   this.experienceLabel = profile.yearsOfExperience;

      if (key === 'stateId') {
        this.formData.stateId = this.cities.find(
          (city) => city.name === value
        ).id;

        console.log(this.formData.stateId);
        return;
      }

      if (key === 'industry') {
        this.formData.industry = name;
        return;
      }

      this.formData[key] = value;
    },
    setNyscStatus(option) {
      this.formData.nyscStatus = option.value;
    },
    getCities(id) {
      authService
        .getStates(id)
        .then((res) => {
          this.cities = res.data.country.states.map((city) => ({
            ...city,
            value: city.name
          }));
        })
        .catch((e) => {
          console.log(e);
        });
    },

    isNumber(value) {
      return typeof value === 'number';
    },
    uploadImg(event) {
      const fileSize = 200 * 1024;
      const img = new FileReader();
      const vm = this;

      img.onload = (e) => {
        console.log(e.total);
        // Max of 5MB
        if (e.total < fileSize) {
          vm.formData.profilePhoto = e.target.result;
          let fd = new FormData();
          fd.append('profilePhoto', event.target.files[0]);
          this.loading = true;
          seekerService
            .uploadProfilePhoto(fd, (percentage) => {
              console.log(percentage);
            })
            .then((res) => {
              this.$handleSuccess(res);
              this.setProfile(res.data.profile);
              // this.$emit("profileChanged", res.data.profile);
            })
            .catch((e) => {
              this.$handleError(e);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$handleError('File too large. Max file size allowed is 200KB');
        }
      };
      img.readAsDataURL(event.target.files[0]);
    },
    updateProfile() {
      //   console.log(this.formData)
      this.updatingProfile = true;
      seekerService
        .updateProfile(this.formData)
        .then((res) => {
          this.$handleSuccess(res);
          this.setProfile(res.data.profile);

          // this.$emit("profileChanged", res.data.profile);
          // this.updateLoggedUser({
          //   profilePhoto: this.formData.profilePhoto
          // });
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.updatingProfile = false;
        });

      //   try {
      //     this.updatingProfile = true;
      //     const { data } = await this.$updateProfile({
      //       url: API.UPDATE_PROFILE,
      //       data: {
      //         ...this.formData,
      //         phone: `${this.formData.phone}`
      //       },
      //       type: "put"
      //     });
      //     if (!data.error) {
      //       this.$notify({
      //         type: "success",
      //         title: "Success",
      //         text: "Profile updated successfully"
      //       });
      //       this.updateLoggedUser({
      //         profilePhoto: this.formData.profilePhoto
      //       });
      //       return;
      //     }
      //     this.$notify({
      //       type: "error",
      //       title: "Error",
      //       text: data.message || "Something went wrong"
      //     });
      //   } catch (error) {
      //     return error;
      //   } finally {
      //     this.updatingProfile = false;
      //   }
    },
    toggleUserOptions() {
      this.showDropdown = !this.showDropdown;
      this.$refs.userOptions.focus();
    },
    removePhoto() {
      this.formData.profilePhoto = '';
      this.showDropdown = !this.showDropdown;
    },
    setGender(gender) {
      this.formData.gender = gender.value;
    },
    setLanguages(languages) {
      this.formData.languages = languages.map((l) => l.name);
    },
    getAllCountries() {
      authService
        .getAllCountries()
        .then((res) => {
          this.countries = res.data.countries;
        })
        .catch()
        .finally();
    }
  }
};
</script>

<style scoped lang="scss">
.mb-1x {
  margin-bottom: 8px;
  display: inline-block;
}

.thumbnail-wrapper {
  position: relative;
  display: inline-block;
}
.edit-icon {
  position: absolute;
  z-index: 9999;
  bottom: 29px;
  right: 3px;
  width: 33px;
}

.avatar-icon {
  opacity: 0.9;
}

.user {
  &__options {
    position: absolute;
    top: 120px;
    left: 50px;
    padding: 16px 24px;
    background: red;
    min-width: 200px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 2px;
    transition: 0.3s all ease-out;
    opacity: 0;
    pointer-events: none;
    outline: none;

    &-list {
      transition: 0.3s all ease-out;

      &:hover {
        background: darken(#fff, 2%);
      }
    }

    &.active {
      top: 110px;
      left: 50px;
      opacity: 1;
      pointer-events: auto;
    }
  }
}
</style>
